<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div v-if="item">
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('admin.tabs.base')">
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.title.label')"
                    label-for="title"
                  >
                    <b-form-input
                      id="title"
                      v-model="item.title"
                      :state="errors && errors.title ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.title">
                      {{ errors.title[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="8"
                >
                  <b-form-group
                    :label="$t('form.url.label')"
                    label-for="url"
                  >
                    <b-form-input
                      id="url"
                      v-model="item.url"
                      :state="errors && errors.url ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.url">
                      {{ errors.url[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.type.label')"
                    label-for="type"
                    :state="errors && errors.type ? false : null"
                  >
                    <v-select
                      id="type"
                      v-model="item.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typesOptions"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.type">
                      {{ errors.type[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.cron.label')"
                    label-for="cron"
                    :state="errors && errors.cron ? false : null"
                  >
                    <v-select
                      id="cron"
                      v-model="item.cron"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="cron"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.cron">
                      {{ errors.cron[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col
                  cols="6"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.sku_prefix.label')"
                    label-for="sku_prefix"
                  >
                    <b-form-input
                      id="sku_prefix"
                      v-model="item.sku_prefix"
                      :state="errors && errors.sku_prefix ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.sku_prefix">
                      {{ errors.sku_prefix[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.currency.label')"
                    label-for="currency_id"
                    :state="errors && errors.currency_id ? false : null"
                  >
                    <v-select
                      id="currency_id"
                      v-model="item.currency_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="currencyOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="currency_id"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.currency_id">
                      {{ errors.currency_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.stock.label')"
                    label-for="stock_id"
                    :state="errors && errors.stock_id ? false : null"
                  >
                    <v-select
                      id="stock_id"
                      v-model="item.stock_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="stocksOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="stock_id"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.stock_id">
                      {{ errors.stock_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.stock_status.label')"
                    label-for="stock_status_id"
                    :state="errors && errors.stock_status_id ? false : null"
                  >
                    <v-select
                      id="stock_status_id"
                      v-model="item.stock_status_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="stockStatusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="stock_status_id"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.stock_status_id">
                      {{ errors.stock_status_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.zero_stock_status.label')"
                    label-for="zero_stock_status_id"
                    :state="errors && errors.zero_stock_status_id ? false : null"
                  >
                    <v-select
                      id="zero_stock_status_id"
                      v-model="item.zero_stock_status_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="stockStatusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="zero_stock_status_id"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.zero_stock_status_id">
                      {{ errors.zero_stock_status_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.empty_stock_status.label')"
                    label-for="empty_stock_status_id"
                    :state="errors && errors.empty_stock_status_id ? false : null"
                  >
                    <v-select
                      id="empty_stock_status_id"
                      v-model="item.empty_stock_status_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="stockStatusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="empty_stock_status_id"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.empty_stock_status_id">
                      {{ errors.empty_stock_status_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col
                  cols="6"
                  md="3"
                >
                  <b-form-group
                    :label="$t('form.clear_stocks.label')"
                    label-for="clear_stocks"
                  >
                    <b-form-checkbox
                      id="clear_stocks"
                      v-model="item.clear_stocks"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.clear_stocks">
                      {{ errors.clear_stocks[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.tabs.columns')">
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.column_product.label')"
                    label-for="column_product"
                  >
                    <b-form-input
                      id="column_product"
                      v-model="item.column_product"
                      :state="errors && errors.column_product ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_product">
                      {{ errors.column_product[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.logic.label')"
                    label-for="column_product_logic"
                    :state="errors && errors.column_product_logic ? false : null"
                  >
                    <v-select
                      id="column_product_logic"
                      v-model="item.column_product_logic"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="logicOptions.filter(val => val.value !== 'skip')"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.column_product_logic">
                      {{ errors.column_product_logic[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.tag.label')"
                    label-for="tag"
                  >
                    <b-form-input
                      id="tag"
                      v-model="item.tag"
                      :state="errors && errors.tag ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.tag">
                      {{ errors.tag[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.column_title.label')"
                    label-for="column_title"
                  >
                    <b-form-input
                      id="column_title"
                      v-model="item.column_title"
                      :state="errors && errors.column_title ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_title">
                      {{ errors.column_title[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.logic.label')"
                    label-for="column_title_logic"
                    :state="errors && errors.column_title_logic ? false : null"
                  >
                    <v-select
                      id="column_title_logic"
                      v-model="item.column_title_logic"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="logicOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.column_title_logic">
                      {{ errors.column_title_logic[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.column_description.label')"
                    label-for="column_description"
                  >
                    <b-form-input
                      id="column_description"
                      v-model="item.column_description"
                      :state="errors && errors.column_description ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_description">
                      {{ errors.column_description[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.logic.label')"
                    label-for="column_description_logic"
                    :state="errors && errors.column_description_logic ? false : null"
                  >
                    <v-select
                      id="column_description_logic"
                      v-model="item.column_description_logic"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="logicOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.column_description_logic">
                      {{ errors.column_description_logic[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.column_sku.label')"
                    label-for="column_sku"
                  >
                    <b-form-input
                      id="column_sku"
                      v-model="item.column_sku"
                      :state="errors && errors.column_sku ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_sku">
                      {{ errors.column_sku[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.column_price.label')"
                    label-for="column_price"
                  >
                    <b-form-input
                      id="column_price"
                      v-model="item.column_price"
                      :state="errors && errors.column_price ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_price">
                      {{ errors.column_price[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.logic.label')"
                    label-for="column_price_logic"
                    :state="errors && errors.column_price_logic ? false : null"
                  >
                    <v-select
                      id="column_price_logic"
                      v-model="item.column_price_logic"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="logicOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.column_price_logic">
                      {{ errors.column_price_logic[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.old_price_percent.label')"
                    label-for="old_price"
                  >
                    <b-form-input
                      id="old_price"
                      v-model="item.old_price"
                      :state="errors && errors.old_price ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.old_price">
                      {{ errors.old_price[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.column_quantity.label')"
                    label-for="column_quantity"
                  >
                    <b-form-input
                      id="column_quantity"
                      v-model="item.column_quantity"
                      :state="errors && errors.column_quantity ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_quantity">
                      {{ errors.column_quantity[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.logic.label')"
                    label-for="column_quantity_logic"
                    :state="errors && errors.column_quantity_logic ? false : null"
                  >
                    <v-select
                      id="column_quantity_logic"
                      v-model="item.column_quantity_logic"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="logicOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.column_quantity_logic">
                      {{ errors.column_quantity_logic[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.column_category.label')"
                    label-for="column_category"
                  >
                    <b-form-input
                      id="column_category"
                      v-model="item.column_category"
                      :state="errors && errors.column_category ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_category">
                      {{ errors.column_category[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.logic.label')"
                    label-for="column_category_logic"
                    :state="errors && errors.column_category_logic ? false : null"
                  >
                    <v-select
                      id="column_category_logic"
                      v-model="item.column_category_logic"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="logicOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.column_category_logic">
                      {{ errors.column_category_logic[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.by_list.label')"
                    label-for="column_category_hard"
                    :state="errors && errors.column_category_hard ? false : null"
                  >
                    <b-form-checkbox
                      id="column_category_hard"
                      v-model="item.column_category_hard"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.column_attribute.label')"
                    label-for="column_attribute"
                  >
                    <b-form-input
                      id="column_attribute"
                      v-model="item.column_attribute"
                      :state="errors && errors.column_attribute ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_attribute">
                      {{ errors.column_attribute[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.logic.label')"
                    label-for="column_attribute_logic"
                    :state="errors && errors.column_attribute_logic ? false : null"
                  >
                    <v-select
                      id="column_attribute_logic"
                      v-model="item.column_attribute_logic"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="logicOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.column_attribute_logic">
                      {{ errors.column_attribute_logic[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.by_list.label')"
                    label-for="column_attribute_hard"
                    :state="errors && errors.column_attribute_hard ? false : null"
                  >
                    <b-form-checkbox
                      id="column_attribute_hard"
                      v-model="item.column_attribute_hard"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.column_image.label')"
                    label-for="column_image"
                  >
                    <b-form-input
                      id="column_image"
                      v-model="item.column_image"
                      :state="errors && errors.column_image ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_image">
                      {{ errors.column_image[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.logic.label')"
                    label-for="column_image_logic"
                    :state="errors && errors.column_image_logic ? false : null"
                  >
                    <v-select
                      id="column_image_logic"
                      v-model="item.column_image_logic"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="logicOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.column_image_logic">
                      {{ errors.column_image_logic[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.column_brand.label')"
                    label-for="column_brand"
                  >
                    <b-form-input
                      id="column_brand"
                      v-model="item.column_brand"
                      :state="errors && errors.column_brand ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_brand">
                      {{ errors.column_brand[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.logic.label')"
                    label-for="column_brand_logic"
                    :state="errors && errors.column_brand_logic ? false : null"
                  >
                    <v-select
                      id="column_brand_logic"
                      v-model="item.column_brand_logic"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="logicOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.column_brand_logic">
                      {{ errors.column_brand_logic[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row class="hidden">
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.column_currency.label')"
                    label-for="column_currency"
                  >
                    <b-form-input
                      id="column_currency"
                      v-model="item.column_currency"
                      :state="errors && errors.column_currency ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.column_currency">
                      {{ errors.column_currency[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.tabs.continue')">
              <b-alert
                variant="info"
                show
                class="mb-3 p-1"
              >
                Буде виконано пропуск товарів, які підпадають хоча б під одне із правил нижче.
              </b-alert>
              <b-row>
                <b-col cols="2">
                  <b-button
                    variant="primary"
                    block
                    @click="addContinue"
                  >
                    <feather-icon
                      icon="PlusIcon"
                    />
                  </b-button>
                </b-col>
                <b-col cols="10">
                  <div class="repeater-form">
                    <b-row
                      v-for="(cont, index) in item.continue_if"
                      :id="'cont' + index"
                      :key="index"
                      ref="row"
                    >
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group>
                          <v-select
                            v-model="cont.f"
                            :options="ciFieldOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="2"
                      >
                        <b-form-group>
                          <v-select
                            v-model="cont.c"
                            :options="ciCompareOptions"
                            :clearable="false"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group>
                          <b-form-input v-model="cont.v" />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="2"
                      >
                        <b-button
                          variant="danger"
                          block
                          @click="removeContinue(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                          />
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.tabs.categories')">
              <b-alert
                variant="info"
                show
                class="mb-1 p-1"
              >
                Буде виконано зв'язку по категоріях та націнку згідно правил нижче.
              </b-alert>
              <b-alert
                variant="warning"
                show
                class="mb-3 p-1"
              >
                Можливі правила маржі для категорій (% до ціни):<br>
                - фіксована (%). напр: 5 <br>
                - за діапазонами (до:%). напр: 500:8.5/1000:5/999999:2.5
              </b-alert>
              <b-row>
                <b-col cols="2">
                  <b-button
                    variant="primary"
                    block
                    @click="addCategories"
                  >
                    <feather-icon
                      icon="PlusIcon"
                    />
                  </b-button>
                </b-col>
                <b-col cols="10">
                  <div class="repeater-form">
                    <b-row
                      v-for="(cat, index) in item.categories"
                      :id="'categories' + index"
                      :key="index"
                      ref="row"
                    >
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group>
                          <b-form-input
                            v-model="cat.source"
                            placeholder="Категорія в прайсі"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group>
                          <v-select
                            v-model="cat.target"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="categoryOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                            placeholder="Категорія на сайті"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="6"
                        md="6"
                      >
                        <b-form-group>
                          <b-form-input
                            v-model="cat.price"
                            placeholder="Правила ціни"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="6"
                        md="2"
                      >
                        <b-form-group>
                          <b-form-input
                            v-model="cat.old_price"
                            placeholder="Коефіцієнт для старої ціни"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="6"
                        md="2"
                      >
                        <b-form-group
                          label="Ств. товари"
                        >
                          <b-form-checkbox
                            v-model="cat.create_products"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="6"
                        md="2"
                      >
                        <b-button
                          variant="danger"
                          block
                          @click="removeCategories(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                          />
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.tabs.attributes')">
              <b-alert
                variant="info"
                show
                class="mb-1 p-1"
              >
                Буде виконано зв'язку по характеристиках згідно правил нижче.
              </b-alert>
              <b-row>
                <b-col cols="2">
                  <b-button
                    variant="primary"
                    block
                    @click="addAttributes"
                  >
                    <feather-icon
                      icon="PlusIcon"
                    />
                  </b-button>
                </b-col>
                <b-col cols="10">
                  <div class="repeater-form">
                    <b-row
                      v-for="(attr, index) in item.attributes"
                      :id="'attributes' + index"
                      :key="index"
                      ref="row"
                    >
                      <b-col
                        cols="12"
                        md="5"
                      >
                        <b-form-group>
                          <b-form-input
                            v-model="attr.source"
                            placeholder="Характеристика в прайсі"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="5"
                      >
                        <b-form-group>
                          <v-select
                            v-model="attr.target"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="attributesOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                            placeholder="Характеристика на сайті"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="2"
                      >
                        <b-button
                          variant="danger"
                          block
                          @click="removeAttributes(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                          />
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
          <hr>
          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'imports-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { mapGetters } from 'vuex'
import GlobalMixin from '@/mixins/GlobalMixin'
import StatusMixin from '@/mixins/StatusMixin'

export default {
  mixins: [StatusMixin, GlobalMixin],
  data() {
    return {
      item: {
        status: 'disabled',
        cron: 'disabled',
        title: null,
        sku_prefix: null,
        tag: null,
        type: 'xml',
        url: null,
        old_price: 1,
        clear_stocks: false,
        column_product: 'items',
        column_title: 'title',
        column_description: 'description',
        column_sku: 'model',
        column_price: 'price',
        column_quantity: 'quantity',
        column_category: 'category',
        column_attribute: 'param',
        column_image: 'image',
        column_brand: 'brand',
        column_currency: 'currency',
        column_product_logic: 'full',
        column_title_logic: 'full',
        column_description_logic: 'full',
        column_sku_logic: 'full',
        column_price_logic: 'full',
        column_quantity_logic: 'full',
        column_category_logic: 'full',
        column_attribute_logic: 'full',
        column_image_logic: 'full',
        column_brand_logic: 'full',
        column_currency_logic: 'full',
        column_category_hard: false,
        column_attribute_hard: false,
        stock_id: 1,
        currency_id: 1,
        stock_status_id: 1,
        zero_stock_status_id: 2,
        empty_stock_status_id: 2,
        continue_if: [],
        categories: [],
        attributes: [],
      },
      typesOptions: ['xml'],
      logicOptions: [
        { label: 'Не обробляти', value: 'skip' },
        { label: 'Додавати', value: 'create' },
        { label: 'Оновлювати', value: 'update' },
        { label: 'Додавати та Оновлювати', value: 'full' },
      ],
      stocksOptions: [],
      currencyOptions: [],
      stockStatusOptions: [],
      attributesOptions: [],
      categoryOptions: [],
      ciFieldOptions: [
        { label: 'Ціна', value: 'price' },
        { label: 'Кількість', value: 'quantity' },
        { label: 'Категорія', value: 'category' },
        { label: 'Назва', value: 'title' },
        { label: 'Артикул', value: 'sku' },
      ],
      ciCompareOptions: ['==', '!=', '>=', '<=', 'like', 'not_like'],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  beforeCreate() {
    this.$http.get('/api/stocks', { params: { mode: 'client', per_page: 100 } })
      .then(response => {
        this.stocksOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    this.$http.get('/api/admin/statuses')
      .then(response => {
        this.stockStatusOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    this.$http('/api/currencies')
      .then(response => {
        this.currencyOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    this.$http.get('/api/admin/fullcategories', { params: { type: 'product', status: 'enabled' } })
      .then(response => {
        this.categoryOptions = this.$options.filters.transformForVSelect(response.data, 'id', 'title')
      })

    this.$http.get('/api/attributes', { params: { status: 'enabled', per_page: 9999 } })
      .then(response => {
        this.attributesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    this.$http.get(`/api/admin/imports/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'imports-index' })
        }
      })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async onSubmit() {
      const data = {
        ...this.item,
      }

      this.$http.put(`/api/admin/imports/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'imports-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    addContinue() {
      this.item.continue_if.push({
        f: 'price',
        c: '===',
        v: 0,
      })
    },
    removeContinue(index) {
      this.item.continue_if.splice(index, 1)
    },
    addCategories() {
      this.item.categories.push({
        source: null,
        target: null,
        price: null,
        old_price: null,
        create_products: false,
      })
    },
    removeCategories(index) {
      this.item.categories.splice(index, 1)
    },
    addAttributes() {
      this.item.attributes.push({
        source: null,
        target: null,
      })
    },
    removeAttributes(index) {
      this.item.attributes.splice(index, 1)
    },
    transformData(item) {
      return {
        ...item,
        continue_if: item.continue_if ?? [],
        categories: item.categories ?? [],
        attributes: item.attributes ?? [],
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.custom-checkbox {
  margin:0 0 15px;
}

.mh_200 {
  max-height: 190px;
  overflow: scroll;
}
</style>
